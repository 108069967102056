import { render, staticRenderFns } from "./ShipmentPictureView.vue?vue&type=template&id=242da48e&scoped=true"
import script from "./ShipmentPictureView.vue?vue&type=script&lang=js"
export * from "./ShipmentPictureView.vue?vue&type=script&lang=js"
import style0 from "./ShipmentPictureView.vue?vue&type=style&index=0&id=242da48e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242da48e",
  null
  
)

export default component.exports