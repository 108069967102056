import http from "../http-common"; 

class PurchaseHistoryService {
  getAllPurchaseHistorys(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/purchaseHistory/purchaseHistorys`, searchDTO);
  }
 

  get(purchaseHistoryId) {
    return this.getRequest(`/purchaseHistory/${purchaseHistoryId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/purchaseHistory?field=${matchData}`, null);
  }

  addPurchaseHistory(data) {
    return http.post("/purchaseHistory/addPurchaseHistory", data);
  }

  update(data) {
  	return http.post("/purchaseHistory/updatePurchaseHistory", data);
  }
  
  uploadImage(data,purchaseHistoryId) {
  	return http.postForm("/purchaseHistory/uploadImage/"+purchaseHistoryId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new PurchaseHistoryService();
